import axiosInstance from "../config/axiosEmpInterceptors";
//import { headers_with_token } from "../helper/helper";

export const getEmployerJobsAPI = (student_id, read = null) => {
  return axiosInstance
    .get(
      `employer/job/active_jobs?student_id=${student_id}${
        read !== null ? `&read=${read}` : ""
      }`
    )
    .then((res) => {
      return res.data;
    });
};

export const getEmployerJobDetails = (job_id, student_id) => {
  return axiosInstance
    .get(`employer/job/show?permalink=${job_id}&student_id=${student_id}`)
    .then((res) => {
      return res.data;
    });
};

export const applyJobAPI = (payload) => {
  return axiosInstance
    .post(`employer/applicant/add`, payload, {
      headers: {
        "Content-Type": "multipart/form-data", // changing this to form-data to support as per API
      },
    })
    .then((res) => {
      return res.data;
    });
};

/** NOTE :: We are skipping pagination for this endpoint as of now discussed with Team on product call 22.03.2022 - TBS */
export const getFilteredEmployerJobsAPI = (student_id, params = {}) => {
  const {
    search = null,
    city = null,
    state = null,
    job_type = null,
    work_type = null,
    publish_date = null,
    company = null,
    country = null,
    experience = null,
    degree = null,
    // page = 1,
    // per_page = 500, // Setting large page count to skip paging confirmed with Haseeb - TBS
    sort = "date",
  } = params;

  // This endpoint returns all data if page & per_page parameters are not passed - TBS
  return axiosInstance
    .get(
      `employer/job/employer_jobs?student_id=${student_id}&sort=${sort}${
        search !== null ? `&keyword=${search}` : ""
      }${city !== null ? `&city=${city}` : ""}${
        state !== null ? `&state=${state}` : ""
      }${country !== null ? `&country=${country}` : ""}${
        job_type !== null ? `&position=${job_type}` : ""
      }${work_type !== null ? `&work_type=${work_type}` : ""}${
        publish_date !== null ? `&publish_date=${publish_date}` : ""
      }${company !== null ? `&company=${company}` : ""}${
        experience !== null ? `&experience=${experience}` : ""
      }${degree !== null ? `&degree=${degree}` : ""}`
    )
    .then((res) => {
      return res.data;
    });
};
